import { Button, Divider, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const ManualAdd = () => {
  const [value, setValue] = useState("GA");
  const [first, setFirst] = useState("");
  const [sur, setSur] = useState("");
  const [vipFirst, setVipFirst] = useState("");
  const [vipSur, setVipSur] = useState("");
  const [vips, setVips] = useState([]);
  const [error, setError] = useState("");

  const addGuest = () => {
    setVips([
      ...vips,
      {
        id: uuidv4(),
        name: vipFirst + " " + vipSur,
        email: "",
        isSurvivor: false,
        barcodes: [],
        emailSent: false,
      },
    ]);
    setVipFirst("");
    setVipSur("");
  };

  const savePurchaserInformation = async () => {
    const requestBody = {
      id: uuidv4(),
      name: first + " " + sur,
      email: "",
      isSurvivor: false,
      type: value,
      paymentLink: null,
      barcodes: [],
      vip: {
        guests: value === "VIP" ? vips : [],
        number: null,
      },
      checkIn: null,
      emailSent: false,
    };

    try {
      const response = await axios.post(
        "https://shine-api.vercel.app/saveGuest",
        requestBody
      );
      return response.data;
    } catch (err) {
      console.error("Error saving purchaser info:", err);
      throw err;
    }
  };

  const handleAdd = async () => {
    const purchaser = await savePurchaserInformation()
    if(purchaser) {
         setFirst("");
         setSur("");
         setVips([]);
         setValue("GA")
    } else {
        setError("Something went wrong. Call Monea' :)")
    }
  };

  return (
    <div className="page-content">
      <div
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <span className="h1">Manual Entry</span>
        <Divider />
        <div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <span>What kind of ticket was purchased?</span>
            <Select
              label="Ticket Type"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <MenuItem value="GA">General Admission</MenuItem>
              <MenuItem value="VIP">VIP Table</MenuItem>
            </Select>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <span>Enter purchaser details: </span>
          <div
            style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
          >
            <TextField
              label={"First Name"}
              value={first}
              onChange={(e) => setFirst(e.target.value)}
            />
            <TextField
              label={"Last Name"}
              value={sur}
              onChange={(e) => setSur(e.target.value)}
            />
          </div>
        </div>
        {value === "VIP" && (
          <div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <span className="h1">Add Remaining Guests</span>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{first + " " + sur}</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                  }}
                >
                  {" "}
                  {vips?.map((vip) => {
                    return <span>{vip.name}</span>;
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <span>{7 - vips.length} Remaining</span>
                <TextField
                  label={"First Name"}
                  value={vipFirst}
                  onChange={(e) => setVipFirst(e.target.value)}
                />
                <TextField
                  label={"Last Name"}
                  value={vipSur}
                  onChange={(e) => setVipSur(e.target.value)}
                />
              </div>
              <Button
                variant="contained"
                onClick={addGuest}
                disabled={vips.length === 7}
              >
                Add Guest
              </Button>
            </div>
          </div>
        )}
        <Button
          variant="contained"
          disabled={
            !first || !sur || (value === "VIP" && vips.length < 7) || error
          }
          onClick={handleAdd}
        >
          Add
        </Button>
        <span className="error">{error}</span>
      </div>
    </div>
  );
};

export default ManualAdd;
