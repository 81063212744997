import "./App.css";
import { ThemeProvider } from "@mui/material";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { theme } from "./theme.js";
import Confirmed from "./Pages/Confirmed.js";
import Landing from "./Components/Landing.js";
import CheckIn from "./Pages/CheckIn";
import Tickets from "./Pages/Tickets";
import Info from "./Pages/Info";
import ManualAdd from "./Pages/ManualAdd.js";

const Root = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Landing />} />
      <Route path="confirmed/:id" element={<Confirmed />} />
      <Route path="/tickets/:id/:guestId?" element={<Tickets />} />
      <Route path="/scan" element={<CheckIn />} />
      <Route path="/info" element={<Info />} />
      <Route path="/add" element={<ManualAdd />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
