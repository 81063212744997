import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfettiExplosion from "react-confetti-explosion";
import { useParams } from "react-router-dom";
import logo from "../Resources/logo.svg";
import "./Confirmed.css";

const Confirmed = () => {
  const [isExploding] = useState(true);
  const colors = ["#fd67a0", "#FFD2E3"];
  const [purchaser, setPurchaser] = useState({});
  let { id } = useParams();

  useEffect(() => {
    fetch("https://shine-api.vercel.app/getGuest/" + id)
      .then((res) => res.json())
      .then(async (foundPurchaser) => {
        setPurchaser(foundPurchaser);
        if (
          foundPurchaser?.paymentLink?.order?.[0].netAmountDueMoney.amount === 0
        ) {
          if (!foundPurchaser.emailSent) {
            return await emailConfirmation(foundPurchaser, null).then(
              async () => {
                return fetch(
                  "https://shine-api.vercel.app/updateGuest/" +
                    foundPurchaser.id,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      emailSent: true,
                    }),
                  }
                )
                  .then(() => {
                    if (foundPurchaser.type === "VIP") {
                      return foundPurchaser.vip.guests?.map(async (g) => {
                        if (!g.emailSent) {
                          return emailConfirmation(foundPurchaser, g).then(
                            async () => {
                              return fetch(
                                "https://shine-api.vercel.app/updateGuest/" +
                                  foundPurchaser.id,
                                {
                                  method: "PUT",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    guests: {
                                      id: g.id,
                                      name: g.name,
                                      email: g.email,
                                      isSurvivor: g.isSurvivor,
                                      barcodes: g.barcodes,
                                      emailSent: true,
                                    },
                                  }),
                                }
                              ).catch((err) => console.error(err));
                            }
                          );
                        }
                      });
                    }
                  })
                  .catch((err) => console.log(err));
              }
            );
          }
        }
      })
      .catch((err) => console.log("idk"));
  }, [id]);

  const emailConfirmation = async (purchaser, guest) => {
    const { name, email, id } = guest ? guest : purchaser;
    const [first, ...lastArray] = name.split(" ");
    const last = lastArray.join(" ");
    let url;
    if (guest) {
      url = `https://pinksneakerball.com/tickets/${purchaser.id}/${id}`;
    } else {
      url = `https://pinksneakerball.com/tickets/${id}`;
    }
    const templateParams = {
      first,
      last,
      email,
      url,
    };
    try {
      await axios.post(
        "https://shine-api.vercel.app/emailMobileTickets",
        templateParams
      );
    } catch (err) {
      console.error("Failed to send email confirmation:", err);
    }
  };

  return (
    <div className="page-view">
      <div className="page-content">
        {isExploding && (
          <ConfettiExplosion colors={colors} particleCount={300} force={0.8} />
        )}
        <div className="logo-div">
          <img src={logo} alt="logo" />
        </div>
        <div className="confirmation-message">
          <span className="h1">You're Confirmed!</span>
          <span>
            An email confirmation with event tickets and payment receipt will be
            sent to <b>{purchaser.email}</b>. Present your mobile ticket(s) or
            reservation details for entry.
          </span>
        </div>
        <div className="reservation-details">
          <div className="r-details">
            <span className="h6">{purchaser.name}</span>
            <span>
              {purchaser.type === "GA" ? "General Admission" : "VIP Table"} x{" "}
              {purchaser.barcodes?.length}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmed;
