import { Button, TextField } from "@mui/material";
import logo from "../Resources/logo.svg";
import React, { useEffect, useState } from "react";
import "./CheckIn.css";
import CheckInToast from "../Components/Toasts/CheckInToast";

/* 
To Do: 
- verify populated users to look right // all guest have been added
-update price 
- verify that list is filtered after paying 
*/

const CheckIn = () => {
  const [purchasersList, setPurchasersList] = useState([]);
  const [purchaser, setPurchaser] = useState({});
  const [availableBarcodes, setAvailableBarcodes] = useState([]);
  const [searchId, setSearchId] = useState("");
  const [barcodeId, setBarcodeId] = useState("");
  const [scanned, setScanned] = useState(false);
  const [success, setSuccess] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [users, setUsers] = useState([]); // To store the fetched detailed users
  const [loading, setLoading] = useState(true); // To track loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        setLoading(true); // Start loading

        // Step 1: Fetch all users from the API
        const response = await fetch(
          "https://shine-api.vercel.app/getAllGuests"
        );
        if (!response.ok) {
          throw new Error("Error fetching users");
        }
        const usersArray = await response.json();
        const manualAdds = usersArray.filter(
          (user) => user.type === "GA" && !user.paymentLink
        );
        const websiteAdds = usersArray.filter(
          (user) => user.type === "GA" && user.paymentLink
        );

        // Step 2: For each user, make an individual API request for more detailed info
        const websiteDetailsResponse = websiteAdds.map(async (user) => {
          const orderId = user.paymentLink.order[0].id;
          const userResponse = await fetch(
            `https://shine-api.vercel.app/order/${orderId}`
          );
          if (!userResponse.ok) {
            throw new Error(`Error fetching user with ID: ${orderId}`);
          }
          return userResponse.json(); // Return detailed user data
        });

        // Step 3: Wait for all user details to be fetched
        const foundOrders = await Promise.all(websiteDetailsResponse);
        const paidOrders = foundOrders.filter(
          (order) => order.order.netAmountDueMoney.amount === "0"
        );
        console.log(paidOrders);
        const ids = paidOrders.map((order) => {
          return order.order.id;
        });
        const paidGuests = websiteAdds.filter((guest) =>
          ids.includes(guest.paymentLink.order[0].id)
        );
        const fullList = manualAdds.concat(paidGuests);
        setPurchasersList(fullList);
      } catch (error) {
        setError(error.message); // Capture any errors
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchAllUsers(); // Call the function inside useEffect
  }, []); // Empty depend

  const getList = async () => {
    return fetch("https://shine-api.vercel.app/getAllGuests")
      .then((res) => res.json())
      .then((list) => {
        const attendees = list.filter(
          (guest) =>
            (guest.paymentLink?.order?.[0].netAmountDueMoney.amount === 0 ||
              !guest.paymentLink) &&
            guest.type !== "donate"
        );
        let codes = [];
        attendees.map((a) => {
          a.barcodes.map((c) => {
            codes.push(c.id);
            return c;
          });
          a.vip.guests?.map((guest) => {
            guest.barcodes?.map((c) => {
              codes.push(c.id);
              return c;
            });
            return guest;
          });
          return a;
        });
        setAvailableBarcodes(codes);
        setPurchasersList(attendees);
      });
  };

  useEffect(() => {
    if (searchId) {
      const list = purchasersList.filter((purchaser) =>
        purchaser.name.toLowerCase().includes(searchId.toLowerCase())
      );
      setFilteredList(list);
    } else {
      setFilteredList(purchasersList);
    }
  }, [searchId, purchasersList]);

  // const handleKeyDown = async (e) => {
  //   if (e.key === "Enter") {
  //     const validCode = availableBarcodes.find((b) => b === barcodeId);
  //     setScanned(true);
  //     if (validCode) {
  //       purchasersList.map((p) => {
  //         const foundPurchaser = p.barcodes.find(
  //           (code) => code.id === barcodeId
  //         );
  //         const foundGuest = p.vip.guests.find((guest) =>
  //           guest.barcodes.some((code) => code.id === barcodeId)
  //         );
  //         if (foundPurchaser) {
  //           setPurchaser(p);
  //           setSuccess(true);
  //           const updatedBarcodes = p.barcodes?.map((code) => {
  //             if (code.id === barcodeId && !code.fulfilled) {
  //               return {
  //                 id: code.id,
  //                 fulfilled: true,
  //                 _id: code._id,
  //                 checkIn: new Date(),
  //               };
  //             }
  //             return code;
  //           });
  //           return fetch(`https://shine-api.vercel.app/updateGuest/${p.id}`, {
  //             method: "PUT",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               barcodes: updatedBarcodes,
  //             }),
  //           }).then(() => getList());
  //         } else if (foundGuest) {
  //           setSuccess(true);
  //           setPurchaser(foundGuest);
  //           const updated = foundGuest.barcodes?.map((code) => {
  //             if (code.id === barcodeId) {
  //               return {
  //                 id: code.id,
  //                 fulfilled: true,
  //                 _id: code._id,
  //                 checkIn: new Date(),
  //               };
  //             } else {
  //               return code;
  //             }
  //           });
  //           const updatedGuests = p.vip.guests.map((guest) => {
  //             if (guest.id === foundGuest.id) {
  //               guest.barcodes = updated;
  //             }
  //             return guest;
  //           });
  //           return fetch(`https://shine-api.vercel.app/updateGuest/${p.id}`, {
  //             method: "PUT",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               vip: {
  //                 guests: updatedGuests,
  //               },
  //             }),
  //           }).then(() => getList());
  //         }
  //         return p;
  //       });
  //     } else {
  //       setSuccess(false);
  //     }
  //   }
  // };

  const handleCheckIn = async (e, guest, p) => {
    let updatedBarcodes;
    if (guest && p) {
      const updatedGuests = p.vip.guests.map((g) => {
        if (g.id === guest.id) {
          const updatedBarcodes = g.barcodes?.map((code) => {
            return {
              _id: code._id,
              id: code.id,
              fulfilled: true,
              checkIn: new Date(),
            };
          });
          g.barcodes = updatedBarcodes;
          return g;
        } else {
          return g;
        }
      });
      await fetch(`https://shine-api.vercel.app/updateGuest/${p.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vip: {
            guests: updatedGuests,
          },
        }),
      }).then((res) => res.json());
    } else {
      updatedBarcodes = guest?.barcodes?.map((code) => {
        return {
          _id: code._id,
          id: code.id,
          fulfilled: true,
          checkIn: new Date(),
        };
      });
      await fetch(`https://shine-api.vercel.app/updateGuest/${guest.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          barcodes: updatedBarcodes,
          checkIn: new Date(),
        }),
      }).then((res) => res.json());
    }
    getList();
  };
  return (
    <div className="page-content">
      <div className="logo-div">
        <img src={logo} alt="logo" />
      </div>
      <div className="search">
        <TextField
          label="Barcode or Name"
          value={searchId ? searchId : barcodeId}
          fullWidth
          onChange={(e) => {
            const value = e.target.value;
            if (isNaN(value) || value === "") {
              setSearchId(value);
            } else {
              setBarcodeId(value);
            }
          }}
        />
      </div>
      <div className="purchasers-list">
        {filteredList.map((purchaser, idx) => {
          return (
            <>
              <div className="purchaser-tile" key={"purchaser" + idx}>
                <div className="check-in-div">
                  <Button
                    variant="contained"
                    disabled={
                      (purchaser.checkIn ? true : false) ||
                      (purchaser.barcodes.length > 0 &&
                        !purchaser.barcodes?.find((code) => !code.fulfilled))
                    }
                    sx={{ color: "white" }}
                    onClick={(e) => handleCheckIn(e, purchaser, null)}
                    fullWidth
                  >
                    Check-In
                  </Button>
                </div>
                <div className="purchase-details">
                  <span className="h6">
                    {purchaser.name +
                      (purchaser.isSurvivor ? " (Survivor)" : "")}
                  </span>
                  {purchaser.barcodes?.length > 0 && (
                    <span className="caption-left">
                      Quantity: {purchaser.barcodes?.length}
                    </span>
                  )}
                  {!purchaser.paymentLink && (
                    <span className="caption-left">
                      Quantity: 1
                    </span>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CheckIn;
