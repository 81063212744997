import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import flyer from "../Resources/flyer.png";
import { details, description, host } from "./data.js";
import "./Landing.css";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import leftArrow from "../Resources/Icons/left-arrow.svg";
import rightArrow from "../Resources/Icons/right-arrow.svg";
import stopSign from "../Resources/Icons/stop.svg";
import { instructions } from "./exam-data";
import ThreeDotsWave from "./ThreeDotsWave.js";
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import backIcon from "../Resources/Icons/back.svg";
import minusIcon from "../Resources/Icons/minus.svg";
import plusIcon from "../Resources/Icons/plus.svg";
import trashIcon from "../Resources/Icons/trash.svg";

/* TODO
- test on android 
-update scheduling link on mocreate
- update price
*/
const Landing = () => {
  const [quantity, setQuantity] = useState(1);
  const [first, setFirst] = useState("");
  const [sur, setSur] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [googleImages, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ticketType, setTicketType] = useState("GA");
  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState("");
  const [vipGuests, setVipGuests] = useState([]);
  const [vipFirst, setVipFirst] = useState("");
  const [vipSur, setVipSur] = useState("");
  const [vipEmail, setVipEmail] = useState("");
  const [adding, setAdding] = useState(false);
  const [isSurvivor, setSurvivor] = useState(false);
  const [isVipSurvivor, setIsVipSurvivor] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const res = await fetch(
          "https://shine-api.vercel.app/getAllImages?folderId=13fi039PTmYEz53WYyS5dhgTE4moUNEzE"
        );
        const imgObjects = await res.json();
        if (Array.isArray(imgObjects)) {
          const fetchImage = imgObjects.map((i) =>
            fetch(`https://shine-api.vercel.app/getImageById/${i.id}`).then(
              (response) => response.blob()
            )
          );
          const imageBlobs = await Promise.all(fetchImage);
          const images = imageBlobs.map((blob) => {
            return { src: URL.createObjectURL(blob) };
          });
          setImages(images);
        } else {
          console.error("Expected an array but received:", imgObjects);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };
    fetchImages();
  }, []);

  const savePurchaserInformation = async () => {
    let requestBody;
    const barcodeIds = [];
    for (let i = 0; i < quantity; i++) {
      barcodeIds.push({
        id: Math.floor(100000 + Math.random() * 900000),
        fulfilled: false,
        checkIn: null,
      });
    }
    if (ticketType === "GA" && action === "purchase") {
      requestBody = {
        id: uuidv4(),
        name: first + " " + sur,
        email: email,
        isSurvivor: isSurvivor,
        type: ticketType,
        paymentLink: null,
        barcodes: barcodeIds,
        vip: {
          guests: [],
          number: null,
        },
        checkIn: null,
        emailSent: false,
      };
    } else if (ticketType === "VIP") {
      requestBody = {
        id: uuidv4(),
        name: first + " " + sur,
        email: email,
        isSurvivor: isSurvivor,
        type: ticketType,
        paymentLink: null,
        barcodes: barcodeIds,
        vip: {
          guests: vipGuests,
          number: null,
        },
        checkIn: null,
        emailSent: false,
      };
    } else {
      requestBody = {
        id: uuidv4(),
        name: first + " " + sur,
        email: email,
        isSurvivor: isSurvivor,
        type: action,
        paymentLink: null,
        barcodes: [],
        vip: {
          guests: [],
          number: null,
        },
        checkIn: null,
        emailSent: false,
      };
    }
    try {
      const response = await axios.post(
        "https://shine-api.vercel.app/saveGuest",
        requestBody
      );
      return response.data;
    } catch (err) {
      console.error("Error saving purchaser info:", err);
      throw err;
    }
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const createPaymentLink = async () => {
    setLoading(true);
    let n, amt;
    if (ticketType === "GA") {
      n = "General Admission";
      amt = 10000;
    } else {
      n = "VIP Table Reservation";
      amt = 100000;
    }
    try {
      const purchaserInfo = await savePurchaserInformation();
      await axios.post("https://shine-api.vercel.app/createCustomer", {
        idempotencyKey: purchaserInfo.id,
        givenName: purchaserInfo.name.split(" ")[0],
        familyName: purchaserInfo.name.split(" ").slice(1).join(" "),
        emailAddress: purchaserInfo.email,
      });
      const paymentLinkResponse = await axios.post(
        "https://shine-api.vercel.app/createFullPaymentLink",
        {
          order: {
            locationId: process.env.REACT_APP_LOCATION_ID,
            customerId: purchaserInfo.id,
            lineItems: [
              {
                name: n,
                quantity: quantity.toString(),
                basePriceMoney: {
                  amount: amt,
                  currency: "USD",
                },
              },
            ],
          },
          checkoutOptions: {
            redirectUrl: `https://pinksneakerball.com/confirmed/${purchaserInfo.id}`,
          },
        }
      );
      const paymentLink = paymentLinkResponse.data.paymentLink.paymentLink.url;
      if (isSafari) {
        window.location.href = paymentLink;
      } else {
        window.open(paymentLink, "_self");
      }
    } catch (error) {
      setLoading(false);
      setError("Something went wrong. Please refresh your page and try again.");
      console.error("Error creating payment link:", error);
    }
  };

  const saveVipGuest = () => {
    setVipGuests([
      ...vipGuests,
      {
        id: uuidv4(),
        name: vipFirst + " " + vipSur,
        email: vipEmail,
        isSurvivor: isVipSurvivor,
        barcodes: [
          {
            id: Math.floor(100000 + Math.random() * 900000),
            fulfilled: false,
            checkIn: null,
          },
        ],
        emailSent: false,
      },
    ]);
    setVipFirst("");
    setVipSur("");
    setVipEmail("");
    setIsVipSurvivor(false);
    if (vipGuests?.length === 6) {
      setAdding(false);
    }
  };

  return (
    <div className="page-view">
      <div className="header"></div>
      <div className="page-content">
        <div
          style={{
            padding: "1rem 2rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <div className="event-details">
            <div className="flyer">
              <img src={flyer} alt="event-flyer" />
            </div>
            <div className="info">
              <div className="heading">
                <span className="h1-overline">
                  Saturday, September 28 | 7PM
                </span>
                <span className="h1">Pink Sneaker Bling Fundraiser Ball </span>
              </div>
              <div className="details">
                {details.map((detail, i) => (
                  <div key={"detail" + i} className="detail">
                    <img src={detail.icon} alt="detail-icon" />
                    <div className="detail-text">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <span className="h4">{detail.text}</span>
                        {detail.button}
                      </div>
                      <i>
                        <span className="h6">{detail.subtext}</span>
                      </i>
                      <span className="body1">{detail.subtext2}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="more-info">
            <div className="description">
              <span className="h1">About This Event</span>
              <span>{description}</span>
            </div>
            <div className="host-info">
              <div className="bio-photo">
                <img src={host.photo} alt="host-bio" />
              </div>
              <div className="contact-details">
                <span className="h4">
                  {host.name} | <span className="h6">{host.title}</span>
                </span>
                <a
                  href="https://shineorganization.org/"
                  target="__blank"
                  alt="website-link"
                >
                  shineorganization.org
                </a>
                <span>404-819-6245</span>
              </div>
            </div>
          </div>
          <div className="gallery">
            <Carousel
              className="carousol"
              images={googleImages}
              isLoop
              shouldLazyLoad
              isAutoPlaying
              hasTransition
              hasMediaButton={false}
              leftIcon={<img src={leftArrow} alt="left-arrow" />}
              rightIcon={<img src={rightArrow} alt="left-arrow" />}
              style={{ width: "100%" }}
            />
          </div>
          <div className="self-exam">
            <div className="section-header">
              <div className="stop-sign">
                <img src={stopSign} alt="stop-sign" />
              </div>
              <span className="h1-overline">
                Have you examined your breast lately?
              </span>
              <span className="h1">Ways To Self-Examine</span>
            </div>
            <div className="exam-instructions">
              {instructions.map((instruction, idx) => (
                <div key={"instruction" + idx} className="instruction-wrapper">
                  <div className="instruction">
                    <span className="num-instruction">{idx + 1}</span>
                    <span>{instruction}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sticky-div">
            {currentPage !== 1 && (
              <IconButton
                onClick={() => {
                  if (currentPage < 4) {
                    setFirst("");
                    setSur("");
                    setEmail("");
                    setSurvivor(false);
                    setTicketType("GA");
                  } else if (currentPage === 4) {
                    setVipGuests([]);
                    setQuantity(1);
                  }
                  setError("");
                  setCurrentPage(currentPage - 1);
                }}
              >
                <img src={backIcon} alt="back-icon" />
              </IconButton>
            )}
            {currentPage === 1 && (
              <div className="btn-group">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setAction("purchase");
                    setCurrentPage(currentPage + 1);
                  }}
                  disabled
                  sx={{ color: "white" }}
                >
                  Purchase Tickets (SOLD OUT)
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setAction("donate");
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  Donate to Non-profit
                </Button>
                {loading && <ThreeDotsWave />}
              </div>
            )}
            {currentPage === 2 && (
              <div className="personal-info">
                <span className="h1">Personal Information</span>
                <div className="input-div">
                  <TextField
                    label="First Name"
                    value={first}
                    onChange={(e) => setFirst(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Last Name"
                    value={sur}
                    onChange={(e) => setSur(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={
                      !email.includes("@") &&
                      email &&
                      "Please enter a valid email."
                    }
                    fullWidth
                  />
                  <FormControlLabel
                    value={isSurvivor}
                    control={
                      <Switch
                        color="primary"
                        checked={isSurvivor}
                        onChange={() => setSurvivor(!isSurvivor)}
                      />
                    }
                    label="Are you a breast cancer survivor?"
                    labelPlacement="start"
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={async () => {
                    if (action === "donate") {
                      window.location.replace("https://square.link/u/fwnEeTjI");
                      return await savePurchaserInformation();
                    } else {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                  disabled={!first || !sur || !email.includes("@")}
                  sx={{ color: "white" }}
                >
                  Next
                </Button>
              </div>
            )}
            {currentPage === 3 && (
              <div className="personal-info">
                <span className="h1">Select ticket type:</span>
                <Select
                  value={ticketType}
                  onChange={(e) => setTicketType(e.target.value)}
                >
                  <MenuItem value="GA">General Admission</MenuItem>

                  <MenuItem value="VIP">VIP Table (Requires 8)</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  Next
                </Button>
              </div>
            )}
            {currentPage === 4 && (
              <div className="actions">
                {ticketType === "GA" ? (
                  <div>
                    <span className="h1">General Admission</span>
                    <div className="qty-btn">
                      <IconButton
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                      >
                        <img src={minusIcon} alt="minus-icon" />
                      </IconButton>
                      <span className="h1">{quantity}</span>
                      <IconButton
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <img src={plusIcon} alt="plus-icon" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="h1">VIP Table</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "1rem",
                      }}
                    >
                      <span className="h6">Reservation Details:</span>
                      <span>{first + " " + sur}</span>
                      {isSurvivor && (
                        <span style={{ color: "var(--primary)" }}>
                          <i>Survivor</i>
                        </span>
                      )}
                      <span>{email}</span>
                    </div>
                    <div className="guest-list">
                      {vipGuests
                        ?.slice(0)
                        .reverse()
                        .map((g, idx) => {
                          return (
                            <div key={`guest${idx}`} className="guest-listing">
                              <IconButton
                                onClick={() => {
                                  const updatedArray = vipGuests?.filter(
                                    (guest) => guest.id !== g.id
                                  );
                                  setVipGuests(updatedArray);
                                }}
                              >
                                <img src={trashIcon} alt="trash-icon" />
                              </IconButton>
                              <span className="vip-num">{idx + 1}</span>
                              <div className="guest-info">
                                <span className="h6">{g.name}</span>
                                {g.isSurvivor && (
                                  <span style={{ color: "var(--primary)" }}>
                                    <i>Survivor</i>
                                  </span>
                                )}
                                <span>{g.email}</span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {adding && (
                      <div className="vip-guest-inputs">
                        <span>{7 - vipGuests.length} remaining</span>
                        <div className="input-div">
                          <TextField
                            label="First Name"
                            value={vipFirst}
                            onChange={(e) => setVipFirst(e.target.value)}
                            fullWidth
                          />
                          <TextField
                            label="Last Name"
                            value={vipSur}
                            onChange={(e) => setVipSur(e.target.value)}
                            fullWidth
                          />
                          <TextField
                            label="Email Address"
                            value={vipEmail}
                            onChange={(e) => setVipEmail(e.target.value)}
                            helperText={
                              !vipEmail.includes("@") &&
                              vipEmail &&
                              "Please enter a valid email."
                            }
                            fullWidth
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={isVipSurvivor}
                                onChange={() =>
                                  setIsVipSurvivor(!isVipSurvivor)
                                }
                              />
                            }
                            label="Are you a breast cancer survivor?"
                            labelPlacement="start"
                            onChange={() => setIsVipSurvivor(!isVipSurvivor)}
                          />
                          <Button
                            onClick={saveVipGuest}
                            variant="contained"
                            disabled={
                              !vipFirst || !vipSur || !vipEmail.includes("@")
                            }
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                    {vipGuests?.length < 7 && !adding && (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setAdding(true)}
                      >
                        Add Guest
                      </Button>
                    )}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  {error && <span className="error">{error}</span>}
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={
                      (ticketType === "VIP" && vipGuests.length !== 7) ||
                      error !== ""
                    }
                    onClick={createPaymentLink}
                  >
                    Pay ${ticketType === "GA" ? quantity * 100 : 125 * 8}
                  </Button>
                </div>
                {loading && <ThreeDotsWave />}
              </div>
            )}
            <div className="support-div">
              <span className="caption">
                Trouble completing your purchase? Call{" "}
                <a className="a-small" href="tel:+14782445055">
                  (478) 244-5055
                </a>{" "}
                for technical assistance.
              </span>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            <span>
              {" "}
              © 2024 All rights reserved. Custom website built by{" "}
              <a
                href="https://www.themocreate.com/"
                target="_blank"
                rel="noreferrer"
              >
                MOCREATE{" "}
              </a>{" "}
              with ♥.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
