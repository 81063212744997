import React, { useEffect, useState } from "react";

const Info = () => {
  const [gaList, setGaList] = useState([]);
  const [vipList, setVipList] = useState([]);

  useEffect(() => {

    async function checkIfPaid(doc) {
      if(doc.paymentLink) {
      const response = await fetch(
        "https://shine-api.vercel.app/order/" + doc.paymentLink?.order?.[0].id
      );
      const data = await response.json();
      return data.order.netAmountDueMoney.amount === '0';
    } else {
      return true;
    }
    };
    async function getGuests() {
      return await fetch("https://shine-api.vercel.app/getAllGuests")
        .then((res) => res.json())
        .then( async (docs) => {
          const filteredList = docs.filter((g) => g.type === 'GA' || g.type === 'VIP')
          const promises = filteredList.map(async (doc) => {
             const isPaid = await checkIfPaid(doc);
             return { doc, isPaid };
          })
              const results = await Promise.all(promises);
            setGaList(results.filter((d) => d.doc.type === 'GA' && d.isPaid))
            setVipList(results.filter((d) => d.doc.type === 'VIP' && d.isPaid))
        });
    }
    getGuests();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        height: "100%",
        gap: "1rem",
        padding: "1rem",
      }}
    >
      <span className="h1">Hi, gorgeous! You're doing great.</span>
      <div style={{ padding: "1rem", display: "flex", gap: "2rem" }}></div>
      <div style={{ display: 'flex', gap: '3rem'}}>
      <div style={{ display: "flex", flexDirection: "column"}}>
        <span className="h1">Tickets sold = {gaList.length}</span>
        {gaList.map((guest) => {
          return <div>{guest.doc.name}</div>;
        })}
      </div>
      <div>
        <span className="h1">VIP Tables = {vipList.length}</span>
        {vipList.map((purchaser) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{purchaser.doc.name}</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 1rem",
                }}
              >
                {purchaser.doc.vip.guests.map((guest) => {
                  return <span>{guest.name}</span>;
                })}
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default Info;
