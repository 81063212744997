import React from "react";
import Barcode from "react-barcode";
import checkIcon from "../Resources/Icons/check.svg";

import "./MobileTicket.css";

//https://www.npmjs.com/package/pdf-merger-js

const MobileTicket = ({ barcode }) => {
  return (
    <div className="page-content-2">
      {console.log(barcode.fulfilled)}
      {barcode.fulfilled ? (
        <div className="checked-in-div">
          <img src={checkIcon} alt="check" />
          <span className="h1">You're checked in.</span>
          <span>{new Date().toLocaleString()}</span>
        </div>
      ) : (
        <>
          <div className="header">
            <span>Present for entry</span>
          </div>
          <div className="barcodes">
            <Barcode value={barcode.id} lineColor="var(--text)" />
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          border: "1px dashed var(--primary)",
        }}
      >
        <span>Saturday, September 28, 2024 | 7PM</span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className="h6">Georgia International Convention Center</span>
          <i>
            {" "}
            <span>International Ballroom</span>
          </i>
          <span>2000 Convention Center Concourse, College Park, GA 30337</span>
        </div>
      </div>
    </div>
  );
};

export default MobileTicket;
