import calendar from "../Resources/Icons/calendar.svg";
import location from "../Resources/Icons/location.svg";
import time from "../Resources/Icons/time.svg";
import tie from "../Resources/Icons/tie.svg";
import money from "../Resources/Icons/money.svg";
import bioPhoto from "../Resources/bio-pic.svg";
import hotel from "../Resources/Icons/hotel.svg"
import { Button } from "@mui/material";

export const details = [
  {
    icon: calendar,
    text: "Saturday, September 28, 2024",
  },
  {
    icon: location,
    text: "Georgia International Convention Center",
    subtext: "International Ballroom",
    subtext2: "2000 Convention Center Concourse, College Park, GA 30337",
  },
  {
    icon: time,
    text: "7 PM",
  },
  {
    icon: tie,
    text: "Formal Attire with Sneakers",
  },
  {
    icon: money,
    text: "$100",
  },
  {
    icon: hotel,
    text: "Host Hotel",
    subtext: "Tru by Hilton Atlanta Airport College Park",
    subtext2: "2101 Convention Center Concourse A, College Park, GA 30337",
    button: <Button sx={{ alignSelf: "center", color: "white"}} variant="contained" href={"https://www.hilton.com/en/book/reservation/flexibledates/?ctyhocn=ATLAZRU&arrivalDate=2024-09-27&departureDate=2024-09-28&groupCode=904&room1NumAdults=1&cid=OH%2CMB%2Cattendmyevent%2CMULTIPR%2COfferCTA%2CMultipage%2CBook%2Ci81912"} target="__blank">Apply Discount</Button>
  },
];

export const description =
  "Join us for a heartfelt evening hosted by The Shine Organization (501c3), dedicated to raising funds and awareness for breast cancer. This special fundraiser aims to support breast cancer research, provide resources for those affected, and promote early detection and education. Attendees will enjoy an inspiring program featuring food, drinks, live entertainment, and vendors, all in the spirit of making a difference. Together, we can contribute to the fight against breast cancer and offer hope to countless individuals and families.";

export const host = {
    photo: bioPhoto, 
    name: "Shalita Thrasher",
    business: "Shine Through Me, Inc.",
    title: "Founder, CEO, Survivor",
    contact: ["404-819-6245"],
}  