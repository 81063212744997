import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fd67a0",
    },
    secondary: {
      main: "#FFD2E3",
    },
    text: {
      primary: "#838383",
    },
  },
});