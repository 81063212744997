import { Box, IconButton, Slide, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MobileTicket from "../Components/MobileTicket";
import logo from "../Resources/logo.svg";
import "./Tickets.css";
import backArrow from "../Resources/Icons/back-arrow.svg";
import forwardArrow from "../Resources/Icons/forward-arrow.svg";

const Tickets = () => {
  const { id, guestId } = useParams();
  const [purchaser, setPurchaser] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState("left");

  const handleNextPage = () => {
    setSlideDirection("left");
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleBackPage = () => {
    setSlideDirection("right");
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {
    fetch("https://shine-api.vercel.app/getGuest/" + id)
      .then((res) => res.json())
      .then((foundPurchaser) => {
        if (!guestId) {
          setPurchaser(foundPurchaser);
        } else {
          const foundVip = foundPurchaser.vip.guests?.find(
            (g) => g.id === guestId
          );
          setPurchaser(foundVip);
        }
      })
      .catch((err) => console.log(err));
  }, [id, guestId]);
  return (
    <div className="page-content">
      <div className="logo-div">
        <img src={logo} alt="logo" />
      </div>
      <div className="barcodesList">
        {purchaser.barcodes?.map((barcode, idx) => {
          return (
            <Box
              key={`barcode-${idx}`}
              className="barcodes-carousel"
              sx={{
                width: "100%",
                height: "100%",
                display: currentPage === idx ? "flex" : "none",
                gap: "1rem",
              }}
            >
              <span className="h1">
                {guestId || purchaser.type === "VIP" ? "VIP" : "General"}{" "}
                Admission
              </span>
              {purchaser.barcodes.length > 1 && (
                <span>
                  {idx + 1}/{purchaser.barcodes.length} Tickets
                </span>
              )}
              <Slide direction={slideDirection} in={currentPage === idx}>
                <Stack
                  spacing={2}
                  direction="row"
                  alignContent="center"
                  justifyContent="center"
                >
                  <MobileTicket barcode={barcode} />
                </Stack>
              </Slide>
            </Box>
          );
        })}
      </div>
      <div className="nav-btn-grp">
        {currentPage > 0 && (
          <IconButton onClick={handleBackPage}>
            <img src={backArrow} alt="back-arrow" />
          </IconButton>
        )}
        {currentPage < purchaser.barcodes?.length - 1 && (
          <IconButton onClick={handleNextPage}>
            <img src={forwardArrow} alt="forward-arrow" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Tickets;
